var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_c('v-select',{attrs:{"items":_vm.subjects,"item-value":"id","outlined":"","dense":"","hide-details":"","disabled":!_vm.admin},on:{"change":_vm.updateRecord},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.8rem","width":"40px","text-align":"center"}},[_vm._v(_vm._s(item.id))])]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.id)+" "+_vm._s(data.item.title)+" ")]}}]),model:{value:(_vm.itemModel.subject_id),callback:function ($$v) {_vm.$set(_vm.itemModel, "subject_id", $$v)},expression:"itemModel.subject_id"}})],1),_c('td',[_vm._v(_vm._s(_vm.itemModel.abbr))]),_c('td',{staticStyle:{"text-align":"center"}},[_c('v-text-field',{staticStyle:{"font-size":"0.8rem"},attrs:{"hide-details":"","type":"number","outlined":"","disabled":!_vm.admin},on:{"blur":_vm.updateRecord},model:{value:(_vm.itemModel.course_mark),callback:function ($$v) {_vm.$set(_vm.itemModel, "course_mark", $$v)},expression:"itemModel.course_mark"}})],1),_c('td',{staticStyle:{"text-align":"center"}},[_c('v-text-field',{staticStyle:{"font-size":"0.8rem"},attrs:{"hide-details":"","type":"number","outlined":"","disabled":!_vm.admin || _vm.disableExamMark,"filled":_vm.filled},on:{"blur":_vm.updateRecord},model:{value:(_vm.itemModel.exam_mark),callback:function ($$v) {_vm.$set(_vm.itemModel, "exam_mark", $$v)},expression:"itemModel.exam_mark"}})],1),_c('td',{staticStyle:{"max-width":"1px","white-space":"nowrap","text-overflow":"ellipsis"}},[_c('v-select',{attrs:{"items":_vm.grades,"item-value":"grade","outlined":"","dense":"","hide-details":"","disabled":!_vm.admin},on:{"change":_vm.updateRecord},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.8rem","width":"40px","text-align":"center"}},[_vm._v(_vm._s(item.grade))])]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.grade)+" "+_vm._s(data.item.description)+" ")]}}]),model:{value:(_vm.itemModel.app1),callback:function ($$v) {_vm.$set(_vm.itemModel, "app1", $$v)},expression:"itemModel.app1"}})],1),_c('td',{staticStyle:{"max-width":"1px","white-space":"nowrap","text-overflow":"ellipsis"}},[_c('v-select',{attrs:{"items":_vm.grades,"item-value":"grade","outlined":"","dense":"","hide-details":"","disabled":!_vm.admin},on:{"change":_vm.updateRecord},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.8rem","width":"40px","text-align":"center"}},[_vm._v(_vm._s(item.grade))])]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.grade)+" "+_vm._s(data.item.description)+" ")]}}]),model:{value:(_vm.itemModel.con1),callback:function ($$v) {_vm.$set(_vm.itemModel, "con1", $$v)},expression:"itemModel.con1"}})],1),_c('td',{staticStyle:{"max-width":"1px","white-space":"nowrap","text-overflow":"ellipsis"}},[_c('v-select',{attrs:{"items":_vm.codedComments1,"item-value":"id","outlined":"","dense":"","hide-details":"","disabled":!_vm.admin},on:{"change":_vm.updateRecord},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.8rem","width":"40px","text-align":"center"}},[_vm._v(_vm._s(item.id))])]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.id)+" "+_vm._s(data.item.detail)+" ")]}}]),model:{value:(_vm.itemModel.coded_comment),callback:function ($$v) {_vm.$set(_vm.itemModel, "coded_comment", $$v)},expression:"itemModel.coded_comment"}})],1),_c('td',{staticStyle:{"max-width":"1px","white-space":"nowrap","text-overflow":"ellipsis"}},[_c('v-select',{attrs:{"items":_vm.codedComments1,"item-value":"id","outlined":"","dense":"","hide-details":"","disabled":!_vm.admin},on:{"change":_vm.updateRecord},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.8rem","width":"40px","text-align":"center"}},[_vm._v(_vm._s(item.id))])]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.id)+" "+_vm._s(data.item.detail)+" ")]}}]),model:{value:(_vm.itemModel.coded_comment_1),callback:function ($$v) {_vm.$set(_vm.itemModel, "coded_comment_1", $$v)},expression:"itemModel.coded_comment_1"}})],1),_c('td',[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.admin},on:{"click":_vm.deleteRecord}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }