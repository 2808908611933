<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
             <v-btn
                x-small
                text
                outlined
                class="mr-2"
                v-bind="attrs"
                v-on="on"
            >
                <span
                    class="mr-2"
                >Lessons</span>
                <v-icon
                    x-small
                    class="mr-2 "
                    
                >
                    mdi-bookshelf
                </v-icon>
            </v-btn>
        </template>
        
        <v-card>
            <v-card-title>
                {{ teacherSelected }}
                <v-spacer></v-spacer>
            </v-card-title>                   
            <v-card-text>                        
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="formTeacherClasses"
                            :items="formClasses"
                            outlined
                            dense
                            label="Form Class"
                            @change="updateFormClass"
                            chips
                            small-chips
                            multiple
                            deletable-chips
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="deanFormClasses"
                            :items="formClasses"
                            outlined
                            dense
                            label="Form Dean"
                            chips
                            small-chips
                            multiple
                            deletable-chips
                            @change="updateFormClassDean"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-data-table
                        :headers="headers"
                        :items="lessons"
                        fixed-header
                        :loading="loadingLessons"
                        height="40vh"
                        :calculate-widths="true"
                        ref="LessonTable"
                        class="lesson-table"
                        :disable-pagination="true"
                        :options="options"
                    >
                        <template v-slot:item="props">
                            <LessonRow
                                v-bind:lesson="props.item"
                                v-bind:lessonClasses="formClasses"
                                v-bind:lessonSubjects="subjects"
                                v-bind:index="props.index"
                                @update-lessons="updateLessons"
                            />
                        </template>
                    </v-data-table>
                </v-row>    
            </v-card-text>
            <v-card-actions class="px-4">
                <span>
                    <span>{{ saveProgress }}</span>
                    <v-progress-circular
                        indeterminate
                        size="16"
                        width="3"
                        v-show="saving"
                        class="ml-2"
                    ></v-progress-circular>
                    <v-icon
                        small
                        color="green" 
                        v-show="saved"
                        class="mx-2"
                    >
                        mdi-check-all
                    </v-icon>
                    <v-icon
                        small
                        color="red"
                        v-show="saveError"
                        class="mx-2"
                    >
                        mdi-alert-circle
                    </v-icon>
                </span>
                <v-spacer></v-spacer>
                <v-btn
                    @click="closeEditLesson"
                    depressed
                >
                    Close Form
                </v-btn>
                <v-btn
                    color="primary"
                    @click="addLesson"
                    depressed
                >
                    New Lesson
                </v-btn>
                <v-btn
                    color="primary"
                    @click="saveLessons"
                    depressed
                >
                    Save Lessons
                </v-btn>
            </v-card-actions>
            <v-overlay
                absolute
                :value="overlay"
                color="secondary"
                opacity="0.8"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LessonRow from './AdminEmployeesLessonsRecord';
export default {
    components: {
        LessonRow
    },

    props: {
        employee: Object,
        formClasses: Array,
    },

    data: function () {
        return {
            headers: [
                {text: 'Subj ID', value: 'subjectId', width: '100', sortable: 'false'},
                {text: 'Subject', value: 'subject', width: '220', sortable: 'false'},
                {text: 'Class', value: 'formClass', width: '240', sortable: 'false'},
                {text: '', value: 'actions', sortable: 'false', width: '30'},
                {text: '', value: 'status', sortable: 'false', width: '30'},
            ],
            formTeacherClasses: [],
            dialog: false,
            deanFormClasses: [],
            lessons: [],
            loadingLessons: true,
            options: {
                itemsPerPage: -1,
            },
            saving: false,
            saved: false,
            saveError: false,
            saveProgress: '',
            defaultLesson: {
                id: '',
                subjectId: '',
                subject: '',
                employeeId: '',
                formClasses: [],
            },
            blankLesson: {},
            defaultFormClassRecord: {
                id: '', 
                employee_id: '',
                class_id: '',
                academic_year_id: '', 
            },
            formClassRecord: {},
            subjects: [],
        }
    },

    computed: {
        ...mapGetters({
            overlay: 'admin/getLessonOverlay',
            teacherLessons: 'admin/getTeacherLessons',
        }),
        teacherSelected(){
            return this.employee?.first_name + ' ' + this.employee?.last_name;
        },
    },

    watch: {
      //
      teacherLessons: {
        handler (val) {
            console.log(val)
        }
      },

      dialog: {
        handler (val) {
            console.log(val);
            if(val) this.initialize();
        }
      }
    },

    methods: {
        ...mapMutations({
            setSelectedEmployeeId: 'admin/setSelectedEmployeeId',
            setOverlay: 'admin/setLessonOverlay',
            setTeacherLessons: 'admin/setTeacherLessons',
            setFormTeacherClasses: 'admin/setFormTeacherClasses',
            setDeanFormClasses: 'admin/setDeanFormClasses',
        }),

        ...mapActions({
            getSubjects: 'admin/getSubjects',
            getTeacherLessons: 'admin/getAdminTeacherLessons',
            getFormTeacherClass: 'admin/getFormTeacherClass',
            getDeanFormClasses: 'admin/getDeanFormClasses',
            postDeanFormClasses: 'admin/postDeanFormClasses', 
            postFormTeacherAssignment: 'admin/postFormTeacherAssignment',
        }),

        async initialize()
        {
            console.log('initializing...')
            this.saved = false;
            this.saving = false;
            this.saveError = false;
            this.saveProgress = '';            
            this.blankLesson = {...this.defaultLesson};                        
            this.blankLesson.employeeId = this.employee.id;
            this.formClassRecord.classes = '';
            this.deanFormClasses = [];
            
            this.setSelectedEmployeeId(this.employee.id);           
            this.setOverlay(true);
            this.editedEmployee = Object.assign({}, this.employee);
            try {
                const [
                    { data: dataSubjects },
                    { data: dataTeacherLessons },
                    { data: dataFormTeacherClasses },
                    { data: dataDeanFormClasses },
                ] = await Promise.all([
                    this.getSubjects(),
                    this.getTeacherLessons(),
                    this.getFormTeacherClass(),
                    this.getDeanFormClasses()
                ]) 

                dataSubjects.forEach(record => {
                    let subject = {
                        id: record.id,
                        title: record.title
                    }
                    this.subjects.push(subject);
                })

                this.lessons = 
                dataTeacherLessons.map(record => {
                    return {
                        id: record.id,
                        subjectId: record.subject_id,
                        subject: record.subject_title,
                        formClasses: record.form_classes,
                        employeeId: this.employee.id
                    }
                })
                //this.setTeacherLessons(this.lessons);
                
                // const { data: dataFormTeacherClasses } = await this.getFormTeacherClass();
                this.formTeacherClasses = dataFormTeacherClasses

                // const { data: dataDeanFormClasses } = await this.getDeanFormClasses();
                this.deanFormClasses = dataDeanFormClasses;

                this.loadingLessons = false;
                this.setOverlay(false);
                
            } catch (error) {
                if(error.response) console.log(error.response)
                console.log(error);
            }
        },

        addLesson(){
            this.lessons.push(this.blankLesson);
        },

        closeEditLesson(){            
            this.lessons = [];
            this.dialog = false;
        },

        async updateFormClass(){
            console.log('updating form class...'); 
            this.saved = false;
            this.saveError = false;
            this.saveProgress = 'Saving ';
            this.saving = true;           
            
            this.setFormTeacherClasses(this.formTeacherClasses)

            try {
                let response = await this.postFormTeacherAssignment();
                console.log(response);
                this.saving = false;
                this.saveError = false;
                this.saveProgress = 'Saved ';
                this.saved = true;
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saving = false;
                this.saved = false;
                this.saveProgress = 'Error Occured';
                this.saveError = true;
            }
        },

        async updateFormClassDean () {
            try {
                this.setDeanFormClasses(this.deanFormClasses);
                const response = await this.postDeanFormClasses();
                console.log(response)
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        updateLessons (data) {
            let { index, lesson, deleteLesson } = data;
            console.log(`index: ${index}, lesson: ${lesson}`)
            console.log(`delete: ${deleteLesson}`)
            
            if(deleteLesson){
                let lessons = this.lessons.slice(0,index).concat(this.lessons.slice(index+1));
                this.lessons = [];
                this.$nextTick(() => {
                    this.lessons = lessons;
                })
                console.log(this.lessons)
                return;
            }

            this.lessons[index] = lesson;
            console.log(this.lessons)
            
        },

        saveLessons () {
            this.setOverlay(true);
            setTimeout(() => {
                this.setOverlay(false);
            }, 500)
        }
    }
}
</script>