<template>
    <v-container fluid>
        <v-data-table
            :headers="studentDataHeadersFiltered"
            :items="studentsFiltered"
            :search="search"
            class="elevation-0"
            fixed-header
            height="60vh"
            :loading="loading"
            hide-default-header
        >
            <template v-slot:top>
                <v-toolbar flat class="mb-6">
                    <v-toolbar-title>
                        <div>Students</div>
                        <div v-if="userAdmin != 'PTA'" >
                            <v-btn
                                :color="current.color"
                                :depressed="current.depressed"
                                class="my-2 mr-2"
                                x-small
                                tile
                                @click="filterCurrent()"
                            >
                                <v-icon 
                                    left
                                    light
                                    v-if="currentStudents"
                                    small
                                >
                                    mdi-check-circle
                                </v-icon>
                                Current
                            </v-btn>
                            <v-btn
                                :color="archive.color"
                                :depressed="archive.depressed"
                                class="ma-2"
                                x-small
                                tile
                                @click="filterArchive()"
                            >
                                <v-icon 
                                    left
                                    light
                                    v-if="!currentStudents"
                                    small
                                >
                                    mdi-check-circle
                                </v-icon>
                                Archive
                            </v-btn>
                        </div>
                    </v-toolbar-title>
                    <v-divider
                        class="ml-4 mr-8"
                        inset
                        vertical
                    ></v-divider>

                    <admin-upload-students
                        v-if="userAdmin != 'PTA' && currentStudents"
                        v-on:update-students="initialize"
                    ></admin-upload-students>

                    <AdminStudentsFields
                        v-if="currentStudents"
                        @update="updateTable"
                        v-bind:dataFields="headers"
                    ></AdminStudentsFields>

                    <v-btn
                        class="mx-3"
                        color="primary"
                        @click="download"
                        small
                        v-if="currentStudents"
                    >
                        <v-icon left>mdi-cloud-download</v-icon>
                        Download Spreadsheet
                    </v-btn>

                    <!-- Dialog Report -->
                    <v-dialog
                        v-model="dialogReport"
                        :max-width="maxWidth"
                        persistent
                    >
                        <v-card
                            v-if="!picturePreview"
                            height="80vh"
                        >
                            <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>

                        </v-card>

                        <v-img
                            v-else
                            max-width="200"
                            :src="src"
                            class="mx-auto ma-6"
                        ></v-img>

                        <v-container
                            fluid
                            class="pa-0"
                        >
                            <v-sheet>
                                <v-row
                                    justify="end"
                                    class="ma-0"
                                >

                                    <v-btn
                                        color="primary"
                                        dark
                                        @click="closeRegistrationForm"
                                        class="my-2 mr-4"
                                    >
                                        Close
                                    </v-btn>

                                </v-row>
                            </v-sheet>
                        </v-container>

                    </v-dialog>

                    <!-- Dialog Reset Password -->
                    <v-dialog
                        v-model="dialogResetPassword"
                        max-width="600px"
                        persistent
                    >
                        <v-card>
                            <v-card-title
                                class="subtitle-1"
                            >
                                {{ dialogResetTitle}}
                            </v-card-title>
                            <v-card-text
                                v-if="resetProgress"
                            >
                                <v-progress-linear
                                    color="primary"
                                    indeterminate
                                    rounded
                                    height="6"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    outlined
                                    @click="closeResetPassword"
                                    small
                                    v-if="cancelButton"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="resetPasswordConfirm"
                                    class="ml-6"
                                    small
                                    v-if="okButton"
                                >
                                    Ok
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="closeResetPassword"
                                    class="ml-6"
                                    small
                                    v-if="okCloseButton"
                                >
                                    Ok
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <!-- Dialog Edit -->
                    <v-dialog
                        v-model="dialogEdit"
                        max-width="500px"
                        persistent
                    >
                        <v-card ref="form">
                            <v-card-title>
                                Edit Student
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="first_name"
                                                v-model="editedItem.first_name"
                                                label="First Name"
                                                hide-details="auto"
                                                :rules="rules"
                                                @blur="updateStudent"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="last_name"
                                                v-model="editedItem.last_name"
                                                label="Last Name"
                                                hide-details="auto"
                                                :rules="rules"
                                                @blur="updateStudent"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="formattedDatefns"
                                                        label="Date of Birth"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        hide-details
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    ref="picker"
                                                    v-model="editedItem.date_of_birth"
                                                    min="1950-01-01"
                                                    @change="saveDate"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="birth_certificate_no"
                                                v-model="editedItem.birth_certificate_no"
                                                label="Birth Cerficate Pin"
                                                hide-details="auto"
                                                :rules="rules"
                                                @blur="updateStudent"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                     <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="editedItem.class_id"
                                                :items="formClasses"
                                                chips
                                                deletable-chips
                                                hide-details
                                                class="pt-0 mt-0"
                                                @change="updateStudent"
                                                :disabled="userAdmin == 'PTA'"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <div
                                    class="pl-6"
                                >
                                    {{ saveStatus }}
                                    <v-progress-circular
                                        indeterminate
                                        v-show="saving"
                                        size="16"
                                        width="3"
                                        class="ml-2"
                                    ></v-progress-circular>
                                    <v-icon
                                        small
                                        v-show="saved"
                                        class="ml-2"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                    <v-icon
                                        small
                                        color="red"
                                        v-show="saveError"
                                        class="ml-2"
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    outlined
                                >
                                    Save
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="closeEdit"
                                    outlined
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-spacer></v-spacer>

                    <!-- Search Field -->
                    <v-card width="400" flat>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search Student"
                            single-line
                            hide-details
                            class=""
                            clearable
                            outlined
                            dense
                        ></v-text-field>
                    </v-card>
                </v-toolbar>

            </template>

            <template
                v-slot:header="{ props: { headers } }"
            >
                <thead>
                    <tr>
                        <th
                            v-for="(item, index) in headers"
                            :key="index"
                            :class="{ 
                                'fixed-left': item.fixedLeft && userAdmin !== 'Admin', 
                                'fixed-left-1': item.fixedLeft1 && userAdmin !== 'Admin', 
                                'fixed-right': item.fixedRight && userAdmin !== 'Admin'
                            }"
                        >
                            <AdminStudentsFieldFilters
                                v-bind:data-field="item"
                            ></AdminStudentsFieldFilters>
                        </th>
                    </tr>
                </thead>
            </template>

            <!-- Table Rows -->
            <template
                v-slot:item="{ item, headers }"
            >
                <tr>
                    <td
                        v-for="(header, index) in headers"
                        :key="index"
                        :class="{ 
                            'fixed-left-td': header.fixedLeft && userAdmin !== 'Admin', 
                            'fixed-left-td-1': header.fixedLeft1 && userAdmin !== 'Admin', 
                            'fixed-right-td': header.fixedRight &&  userAdmin !== 'Admin'
                        }"
                    >
                        <template
                            v-if="header.value == 'actions'"
                        >
                            <v-tooltip 
                                v-if="userAdmin != 'PTA'"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        medium
                                        class="mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="showRegistrationForm(item)"
                                    >
                                        mdi-file-outline
                                    </v-icon>
                                </template>
                                <span>Registration Form</span>
                            </v-tooltip>

                            <v-tooltip
                                bottom
                                
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        medium
                                        @click="showEdit(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mr-2"
                                    >
                                        mdi-account-edit
                                    </v-icon>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>

                            <v-tooltip
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        medium
                                        @click="showResetPassword(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-lock-reset
                                    </v-icon>
                                </template>
                                <span>Reset Password</span>
                            </v-tooltip>


                            <v-menu
                                offset-y
                                v-if="userAdmin != 'PTA'"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        medium
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-3"
                                    >
                                        mdi-dots-vertical
                                    </v-icon>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(file, index) in files"
                                        :key="index"
                                        link
                                        @click="displayFile(item, file)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                color="primary"
                                                v-if="item[file.model]"
                                            >
                                                mdi-cloud-check
                                            </v-icon>
                                            <v-icon
                                                v-else
                                                color="red"
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            {{ file.title }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-else>{{ item[header.value] }}</template>
                    </td>
                </tr>
            </template>

        </v-data-table>
        <div class="text-right pt-4 caption">
            Total Number of Students: {{ totalRecords }}
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>

        </v-overlay>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AdminUploadStudents from './AdminUploadStudents';
import { format, parseISO } from 'date-fns';
import AdminStudentsFields from './AdminStudentsFields.vue';
import AdminStudentsFieldFilters from './AdminStudentsFieldsFilters.vue';
export default {
    name: 'AdminDashboard',
    components: {
        AdminUploadStudents,
        AdminStudentsFields,
        AdminStudentsFieldFilters,
    },

    created () {
        this.initialize();
    },

    data: () => ({

        headers:[
            { 
                text: 'ID', 
                value: 'id', 
                default: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filter: false, 
                width: '80px',
                fixedLeft: true,
            },
            { 
                text: 'Name', 
                value: 'name', 
                default: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filter: false,
                width: '200px',
                fixedLeft1: true,
            },
            { 
                text: 'Class', 
                value: 'class_id', 
                default: true, 
                filters: true, 
                customFilter: true, 
                menu: false, 
                filterHeaders: [{text: 'Class', value: 'id', filterable: false}], 
                filterItems: [], 
                singleSelect:false, 
                selected: [], 
                filter: false,
                width: '80px'
            },
            { 
                text: 'Date of Birth', 
                value: 'formatted_date_of_birth', 
                default: true, 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect: true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '100px'
            },
            { 
                text: 'Birth Certificate Pin', 
                value: 'birth_certificate_no', 
                default: true, 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:false, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px'
            },
            { 
                text: 'Actions', 
                value: 'actions', 
                sortable: false, 
                default: true, 
                // admin: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px',
                fixedRight: true,
            },
            { 
                text: 'Home Address', 
                value: 'home_address', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px',
            },
            { 
                text: 'Town', 
                value: 'address_line_2', 
                filters: true, 
                customFilter: true, 
                menu: false, 
                filterHeaders: [{text: 'Town', value: 'text', filterable: false }], 
                filterItems: [], 
                singleSelect: false, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px',
            },
            { 
                text: 'Phone(H)', 
                value: 'phone_home', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '80px',
            },
            { 
                text: 'Phone (C)', 
                value: 'phone_cell', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '80px',
            },
            { 
                text: 'Email', 
                value: 'email', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '240px'
            },
            { 
                text: "Father's Name", 
                value: 'father_name', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '100px'
            },
            { 
                text: "Father's Home Address", 
                value: 'father_home_address', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px',
            },
            { 
                text: "Father's Phone (H)", 
                value: 'father_phone_home', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '100px',
            },
            { 
                text: "Father's Occupation", 
                value: 'father_occupation', 
                filters: true, 
                customFilter: true, 
                menu: false, 
                filterHeaders: [{text: 'Occupation', value: 'text', filterable: false }], 
                filterItems: [], 
                singleSelect: false, 
                selected: [], 
                filter: false,
                width: '120px'
            },
            { 
                text: "Father's Business Place", 
                value: 'father_business_place', 
                filters: true, 
                customFilter: false, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px'
            },
            { 
                text: "Father's Business Address", 
                value: 'father_business_address', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px',
            },
            { 
                text: "Father's Business Phone", 
                value: 'father_business_phone', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px',
            },
            { 
                text: "Father's Email", 
                value: 'email_father', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '240px'
            },
            { 
                text: "Father's Phone (M)", 
                value: 'mobile_phone_father', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px',
            },
            { 
                text: "Mother's Name", 
                value: 'mother_name', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '100px',
            },
            { 
                text: "Mother's Home Address", 
                value: 'mother_home_address', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px'
            },
            { 
                text: "Mother's Phone (H)", 
                value: 'mother_phone_home', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Mother's Occupation", 
                value: 'mother_occupation', 
                filters: true, 
                customFilter: true, 
                menu: false, 
                filterHeaders: [{text: 'Occupation', value: 'text', filterable: false }], 
                filterItems: [], 
                singleSelect: false, 
                selected: [], 
                filter: false,
                width: '150px',
            },
            { 
                text: "Mother's Business Place", 
                value: 'mother_business_place', 
                filters: true, 
                customFilter: false, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '200px',
            },
            { 
                text: "Mother's Business Address", 
                value: 'mother_business_address', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '200px',
            },
            { 
                text: "Mother's Business Phone", 
                value: 'mother_business_phone', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px'
            },
            { 
                text: "Mother's Email", 
                value: 'email_mother', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px',
            },
            { 
                text: "Mother's Phone (M)", 
                value: 'mobile_phone_mother', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Guardian's Name", 
                value: 'guardian_name', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '150px'
            },
            // { 
            //     text: "Guardian's Occupation", 
            //     value: 'guardian_occupation', 
            //     filters: true, 
            //     filterHeaders: [{text: 'Occupation', value: 'text', filterable: false }], 
            //     customFilter: true, 
            //     menu: false, 
            //     filterItems: [], 
            //     singleSelect: false, 
            //     selected: [], 
            //     filterable: false, 
            //     filter: false,
            // },
            { 
                text: "Guardian's Business Place", 
                value: 'guardian_business_place', 
                filters: true, 
                customFilter: false, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '180px'
            },
            { 
                text: "Guardian's Business Address", 
                value: 'guardian_business_address', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '200px',
            },
            { 
                text: "Guardian's Business Phone",
                value: 'business_phone_guardian', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '180px'
            },
            { 
                text: "Guardian's Phone (M)", 
                value: 'mobile_phone_guardian', 
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Middle Name", 
                value: 'middle_name', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Emergency Contact", 
                value: 'emergency_contact', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Guardian Occupation", 
                value: 'guardian_occupation', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Guardian Phone", 
                value: 'guardian_phone', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Medical History", 
                value: 'medical_history', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Entry Date", 
                value: 'entry_date', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Details", 
                value: 'details', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Previous School", 
                value: 'previous_school', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Religion Code", 
                value: 'religion_code', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Ethnic Group Code", 
                value: 'ethnic_group_code', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Place of Birth", 
                value: 'place_of_birth', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Nationality", 
                value: 'nationality', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Hobbies", 
                value: 'hobbies', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Sex", 
                value: 'sex', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Sea Number", 
                value: 'sea_no', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "House Code", 
                value: 'house_code', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Achievements", 
                value: 'achievements', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Transport", 
                value: 'transport', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "School Feeding", 
                value: 'school_feeding', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Date of Leaving", 
                value: 'date_of_leaving', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Activities", 
                value: 'activities', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Repeater", 
                value: 'repeater', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Transfer In", 
                value: 'transfer_in', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "ID Card Father", 
                value: 'id_card_father', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "ID Card Mother", 
                value: 'id_card_mother', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "ID Card Guardian", 
                value: 'id_card_guardian', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Living Status", 
                value: 'living_status', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Internet Access", 
                value: 'internet_access', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Device Type", 
                value: 'device_type', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Blood Type", 
                value: 'blood_type', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Regional Corporation", 
                value: 'regional_corporation', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Hepatitis", 
                value: 'hepatitis', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Polio", 
                value: 'polio', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Diphtheria", 
                value: 'diphtheria', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Tetanus", 
                value: 'tetanus', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Yellow Fever", 
                value: 'yellow_fever', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Measles", 
                value: 'measles', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "TB", 
                value: 'tb', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Chicken Pox", 
                value: 'chicken_pox', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Typhoid", 
                value: 'typhoid', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Rheumatic Fever", 
                value: 'rheumatic_fever', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Poor Eyesight", 
                value: 'poor_eyesight', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Poor Hearing", 
                value: 'poor_hearing', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Diabetes", 
                value: 'diabetes', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Asthma", 
                value: 'asthma', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Epilepsy", 
                value: 'epilepsy', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Allergy", 
                value: 'allergy', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Special Consideration", 
                value: 'special_consideration', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Relative in School", 
                value: 'relative_in_school', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Emergency Home Phone", 
                value: 'emergency_home_phone', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Relation to Child", 
                value: 'relation_to_child', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Place in Family", 
                value: 'place_in_family', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Number in Family", 
                value: 'no_in_family', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Number at Home", 
                value: 'no_at_home', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Marital Status (M)", 
                value: 'mother_marital_status', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Marital Status (F)", 
                value: 'father_marital_status', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Marital Status (G)", 
                value: 'guardian_marital_status', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Other Illness", 
                value: 'other_illness', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Guardian Email", 
                value: 'email_guardian', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Home Address Guardian", 
                value: 'home_address_guardian', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
            { 
                text: "Home Phone Guardian", 
                value: 'home_phone_guardian', 
                admin: true,
                filters: true, 
                menu: false, 
                filterHeaders: [], 
                filterItems: [], 
                singleSelect:true, 
                selected: [], 
                filterable: false, 
                filter: false,
                width: '120px'
            },
        ],
        headersFilter: [],
        students: [],
        // studentsFiltered: [],
        search: '',
        totalRecords: '-',
        loading: false,
        dialogReport: false,
        picturePreview: false,
        dialogResetPassword: false,
        dialogEdit: false,
        src: '',
        selectedIndex: -1,
        selectedRecord: {},
        defaultRecord: {
            id: '',
            name: '',
            first_name: '',
            last_name: '',
            classId: '',
            dob: '',
            birth_certificate_no: '',
            progress: '',
            updatedAt: '',
        },
        editedItem: {},
        dialogResetTitle: '',
        cancelButton: true,
        okButton: true,
        okCloseButton: false,
        resetProgress: false,
        menu: false,
        date: null,
        rules: [
            value => !!value || 'Required'
        ],
        saving: false,
        saved: false,
        saveError: false,
        saveStatus: '',
        formClasses: [],
        files: [
            {title: 'Birth Certificate', model: 'birth_certificate'},
            {title: 'Immunization Card', model: 'immunization_card'},
            {title: 'SEA Placement Slip', model: 'sea_slip'},
            {title: 'Passport Photo', model: 'picture'},
            {title: 'Passport Bio Page', model: 'passport'},
            {title: 'Student Permit', model: 'student_permit'},
        ],
        maxWidth: '',
        overlay: false,
        filterItemsPerPage: -1,
        options: {
            fixedColumns: {
                leftColumns: 1,
                rightColumns: 1
            }
        },
        current: {
            color: 'primary',
            depressed: false
        },
        archive: {
            color: 'gray',
            depressed: true
        },
        currentStudents: true,
        studentsArchive: [],
        studentsCurrent: [],
    }),

    computed: {
      formattedDatefns () {
        return this.editedItem.date_of_birth ? format(parseISO(this.editedItem.date_of_birth), 'dd-MMM-yyyy') : '';
      },

      ...mapGetters({
        userAdmin: 'admin/getUserAdmin',
        studentDataHeaders: 'admin/getStudentDataHeaders',
        studentDataFilters: 'admin/getStudentDataFilters',
        studentsFiltered: 'admin/getStudentsFiltered',
      }),

      studentDataHeadersFiltered () {
        if(this.userAdmin === 'Admin') return this.studentDataHeaders.filter(value => {
            return value.default
        })
        return this.studentDataHeaders;
      }

    },

    watch: {
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        date (val) {
            this.editedItem.date_of_birth = val;
        }
    },
    methods: {
        ...mapActions({
            getStudents: 'admin/getAdminStudents',
            resetPassword: 'admin/resetPassword',
            postStudent: 'admin/postStudent',
            getFormClasses: 'admin/getFormClasses',
            downloadStudentData: 'admin/downloadStudentData',
            getTownFilters: 'admin/getTownFilters',
            getOccupationFilters: 'admin/getOccupationFilters',
        }),

        ...mapMutations({
            setEditedStudent: 'admin/setEditedStudent',
            setStudentDataHeaders: 'admin/setStudentDataHeaders',
            setStudentDataSelected: 'admin/setStudentDataSelected',
            setStudentDataFilters: 'admin/setStudentDataFilters',
            setProgressOverlay: 'admin/setProgressOverlay',
            setStudentsFiltered: 'admin/setStudentsFiltered',
            setStudentsCurrent: 'admin/setStudentsCurrent',
            setStudentDataParent: 'admin/setStudentDataParent',
        }),

        async initialize () {
            this.loading = true;
            this.setProgressOverlay(true);
            this.setStudentDataHeaders([]);
            let headersFilter = [];
            

            try {
                this.setStudentDataParent('father_occupation');
                const [
                    { data: dataTownFilters },
                    { data: dataFormClasses },
                    { data: dataOccupationFiltersFather }
                ] = await Promise.all([
                    this.getTownFilters(),
                    this.getFormClasses(),
                    this.getOccupationFilters(),
                    this.getStudentData(),
                ])

                
                this.setStudentDataParent('mother_occupation');
                const { data:dataOccupationFiltersMother } = await this.getOccupationFilters();

                this.setStudentDataParent('guardian_occupation');
                const { data:dataOccupationFiltersGuardian } = await this.getOccupationFilters();
                
                dataFormClasses.forEach(value => {
                    value.text = value.id;
                    value.value = value.id;
                    this.formClasses.push(value.id);
                });

                // console.log(dataTownFilters)
                this.loading = false

                this.headers.forEach(header => {
                    switch (header.text) {
                        case "Class":
                            header.filterItems = dataFormClasses.filter(value => value.form_level);
                            header.selected = dataFormClasses.filter(value => value.form_level);
                            break;
                        case "Town":
                            header.filterItems = dataTownFilters;
                            header.selected = dataTownFilters;
                            break;
                        case "Father's Occupation":
                            header.filterItems = dataOccupationFiltersFather;
                            header.selected = dataOccupationFiltersFather;
                            break;
                        case "Mother's Occupation":
                            header.filterItems = dataOccupationFiltersMother;
                            header.selected = dataOccupationFiltersMother;
                            break;
                        case "Guardian's Occupation":
                            header.filterItems = dataOccupationFiltersGuardian;
                            header.selected = dataOccupationFiltersGuardian;
                            break;
                        default:
                            break;
                    }
                   
                })

                // console.log(this.headers)

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }

            let actionsIndex = -1;

            headersFilter = this.headers.filter((header, index) => {
                if(this.userAdmin == 'PTA' && header.default && !header.admin) {
                    return true;
                }

                if(header.value === 'actions') actionsIndex = index;

                if(this.userAdmin === 'PTA') return false;

                if(header.default) return true;

            })

            if(actionsIndex !== -1 && this.userAdmin === 'Admin'){
                headersFilter.push(headersFilter.splice(actionsIndex, 1)[0]);
            }

            this.setStudentDataHeaders(headersFilter)
            this.setProgressOverlay(false);

            if(!this.currentStudents){
                this.filterArchive();
            }
        },

        customStudentFilter (value, search, item) {
            console.log('custom filter')
            console.log(value)
            console.log(search)
            console.log(item)
            return true;
        },

        async getStudentData () {
            this.loading = true;
            this.students = [];
            this.studentsArchive = [];
            const { data } = await this.getStudents();
            const { current_students:students, archived_students:studentsArchived } = data;
            this.totalRecords = students.length;
            const studentsFiltered = [];

            students.forEach(record => {
                let studentRecord = {};
                studentRecord.name = record.last_name + ', ' + record.first_name
                studentRecord = Object.assign(studentRecord, record);
                studentRecord.formatted_date_of_birth = this.formatDate(studentRecord.date_of_birth);
                studentRecord.birth_certificate = record.file_birth_certificate;
                studentRecord.immunization_card = record.file_immunization_card;
                studentRecord.sea_slip = record.file_sea_slip;
                studentRecord.picture = record.picture;
                studentRecord.passport = record.file_passport;
                studentRecord.student_permit = record.file_student_permit;
                this.students.push(studentRecord)
                studentsFiltered.push(studentRecord)
            })

            studentsArchived.forEach(record => {
                let studentRecord = {};
                studentRecord.name = record.last_name + ', ' + record.first_name
                studentRecord = Object.assign(studentRecord, record);
                studentRecord.formatted_date_of_birth = this.formatDate(studentRecord.date_of_birth);
                studentRecord.birth_certificate = record.file_birth_certificate;
                studentRecord.immunization_card = record.file_immunization_card;
                studentRecord.sea_slip = record.file_sea_slip;
                studentRecord.picture = record.picture;
                studentRecord.passport = record.file_passport;
                studentRecord.student_permit = record.file_student_permit;
                this.studentsArchive.push(studentRecord)
            })


            
            this.setStudentsFiltered(studentsFiltered);
            this.setStudentsCurrent(this.students);
        },

        closeForm () {
            this.dialogReport = false
            this.$nextTick(() => {
                this.selectedRecord = Object.assign({}, this.defaultRecord)
                this.selectedIndex = -1
            })
        },

        closeResetPassword () {
            this.dialogResetPassword = false
            this.dialogResetTitle = ''
            this.resetProgress = false;
            this.cancelButton = true;
            this.okButton = true;
            this.okCloseButton = false;
            this.$nextTick(() => {
                this.selectedRecord = Object.assign({}, this.defaultRecord)
                this.selectedIndex = -1
            })
        },

        showRegistrationForm (item) {
            this.selectedIndex = this.students.indexOf(item)
            this.selectedRecord = Object.assign({}, item)
            console.log(this.selectedRecord);
            this.maxWidth = "80vw";
            this.picturePreview = false;
            this.src = process.env.VUE_APP_API_URI + '/api/registration-form/' + this.selectedRecord.id;
            this.dialogReport = true;
        },

        showResetPassword (item) {
            this.selectedIndex = this.students.indexOf(item)
            this.selectedRecord = Object.assign({}, item)
            this.dialogResetTitle = 'Are you sure you want to reset ' + this.selectedRecord.name + ' password?'
            this.dialogResetPassword = true
        },

        showEdit (item) {
            this.editedItem = Object.assign({}, item);
            if(this.editedItem.date_of_birth){
                 this.editedItem.date_of_birth = format(parseISO(item.date_of_birth), 'yyyy-MM-dd')
            }
            this.dialogEdit = true;
        },

        async updateStudent () {
            this.saveStatus = "Updating";
            this.saveError = false;
            this.saved = false;
            this.saving = true;
            // console.log(this.editedItem);
            const {
                id: studentId,
                first_name: firstName,
                last_name: lastName,
                date_of_birth: dateOfBirth,
                birth_certificate_no: birthCertificateNo,
                class_id: classId,
            } = this.editedItem;

            this.setEditedStudent({
                id: studentId,
                first_name: firstName,
                last_name: lastName,
                date_of_birth: dateOfBirth,
                birth_certificate_no: birthCertificateNo,
                class_id: classId
            });
            //console.log(this.editedItem);
            // console.log(this.editedItem);
            try {
                let response = await this.postStudent();
                console.log(response.status);
                this.initialize();
                this.saveStatus = "Updated Successfully."
                this.saving = false;
                this.saved = true;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saveStatus = "Error Occured."
                this.saving = false;
                this.saveError = true;
            }
        },

        resetPasswordConfirm () {
            //console.log(this.students[this.selectedIndex]);
            this.dialogResetTitle = 'Resetting ' + this.selectedRecord.name + ' password'
            this.resetProgress = true;
            this.cancelButton = false;
            this.okButton = false;
            let id = this.students[this.selectedIndex].id;
            this.resetPassword(id)
            .then(response => {
                console.log(response)
                if(response.status === 200){
                    this.dialogResetTitle = this.selectedRecord.name + ' password reset successfully.'
                    this.resetProgress = false;
                    this.cancelButton = false;
                    this.okButton = false;
                    this.okCloseButton = true;
                }
            })
            .catch(error => {
                if(error.response) console.log(error.response);
                else console.log(error)
            })
            //this.closeResetPassword()
        },

        closeRegistrationForm(){
            this.src = ''
            this.dialogReport = false
            this.editedItem
        },

        formatDate(date){
            if(!date) return null;
            return format(parseISO(date), 'dd-MMM-yyyy');
        },

        saveDate(date){
            this.$refs.menu.save(date);
            this.updateStudent();
        },

        closeEdit(){
            this.dialogEdit = false;
        },

        displayFile (record, file) {
            console.log('record', record);
            let src = record[file.model]
            if(src && file.model != 'picture'){
                this.maxWidth = "80vw";
                this.src = src;
                this.picturePreview = false;
                this.dialogReport = true;
            }
            else if(src && file.model == 'picture'){
                this.maxWidth = "300";
                this.picturePreview = true;
                this.src = src;
                this.dialogReport = true;
            }

        },

        async updateTable () {
            await this.getStudentData();
            this.loading = false;
        },

        async download () {
            this.overlay = true;
            
            const data = this.studentsFiltered.map(student => {
                return Object.fromEntries(
                    this.studentDataHeaders.map(header => [header.value, student[header.value]])
                )
            })

            this.setStudentDataSelected(data);
            try {
                const response = await this.downloadStudentData();
                // console.log(response)
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                link.setAttribute('download', 'Registration Data.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                if(error.response) console.log(error);
                console.log(error);
            }
            this.overlay = false;
        },

        applyFilter (item) {
            // console.log(item)
            const filters = {...this.studentDataFilters};
            filters[item.value] = item.selected;
            this.setStudentDataFilters(filters);
            this.studentsFiltered = [];
            this.studentsFiltered = this.students.filter(student =>
                item.selected.some(sel => sel.id === student.class_id)
            )
        },

        filterCurrent () {
            this.archive.color = 'gray';
            this.archive.depressed = true;
            this.current.color = 'primary';
            this.current.depressed = false;
            this.setStudentsFiltered([]);
            this.currentStudents = true;
            this.$nextTick(() => {
                this.setStudentsFiltered(this.students)
                this.totalRecords = this.students.length
            })
        },

        filterArchive () {
            this.archive.color = 'primary';
            this.archive.depressed = false;
            this.current.color = 'gray';
            this.current.depressed = true;
            this.setStudentsFiltered([]);
            this.currentStudents = false;
            this.$nextTick(() => {
                this.setStudentsFiltered(this.studentsArchive)
                this.totalRecords = this.studentsArchive.length
            })
        },

    }
}
</script>

<style scoped>
    .fixed-left, .fixed-right, .fixed-left-1 {
        position: sticky;
        background-color: #fff;
        z-index: 3 !important;
    }

    .fixed-left-td, .fixed-right-td, .fixed-left-td-1 {
        position: sticky;
        background-color: #fff;
        z-index: 2 !important;
    }

    .fixed-left {
        left: 0;
        
    }

    .fixed-left-1 {
        left: 112px;
        border-right: 1px solid gainsboro;
    }

    .fixed-right {
        right: 0;
        border-left: 1px solid gainsboro;
    }

    .fixed-left-td {
        left: 0;
    }

    .fixed-left-td-1 {
        left: 112px;
        border-right: 1px solid gainsboro;
    }

    .fixed-right-td {
        right: 0;
        border-left: 1px solid gainsboro;
    }
</style>