<template>
    <div>
        <v-alert
            v-if="showWarning"
            text
            outlined
            color="deep-orange"
        >
            <p :key="countdownMessageKey">{{ countdownMessage }}</p>
        </v-alert>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    // created () {
    //     this.startInactivityTimer();
    // },

    // destroyed () {
    //     this.clearInactivityTimer();
    // },

    computed: {
        ...mapGetters({
            userAdmin: 'admin/getUserAdmin',
        })
    },

    data () {
        return {
            showWarning: false,
            countdownMessage: '',
            countdownTime: 60,
            logoutTimeout: null,
            countdownMessageKey: 0,
            timeoutTime: 300000,
        }
    },

    methods: {
        ...mapActions({
            signOut: 'auth/signOut',
        }),

        ...mapMutations({
            setInActivity: 'auth/setInActivity',
            setStep: 'auth/setStep',
            setExpand: 'auth/setExpand',
        }),

        startInactivityTimer () {
            this.logoutTimeout = setTimeout(() => {
                this.logoutUser();
            }, this.timeoutTime);

            setTimeout(() => {
                // this.showWarning = true;
                this.updateCountdownMessage();
            }, 240000)
        },

        clearInactivityTimer () {
            clearTimeout(this.logoutTimeout);
            // this.showWarning = false;
        },

        updateCountdownMessage () {
            let secondsLeft = this.countdownTime;
            this.countdownMessage = `You will be logged out in ${secondsLeft} seconds`;
            this.countdownMessageKey++;
            let countdownInterval = setInterval(() => {
                secondsLeft--;
                this.countdownMessage = `You will be logged out in ${secondsLeft} seconds`;
                this.countdownMessageKey++;
                if(secondsLeft <= 0) {
                    clearInterval(countdownInterval);
                }
            }, 1000)
        },

        async logoutUser () {
            this.setInActivity(true)
            this.setStep(1);
            this.setExpand(true);
            try {
                await this.signOut();
            } catch (error) {
                if(error.response){
                    console.log(error.response);
                }
                console.log(error.response)
            }

            if(this.userAdmin){
                
                this.$router.replace('/admin');
                return;
            }
            this.$router.replace('/')
        },

        resetInactivityTimer () {
            this.clearInactivityTimer();
            this.startInactivityTimer();
        },

        
    },

    mounted () {
        if(this.userAdmin) this.timeoutTime = 54000000
        this.startInactivityTimer();
        window.addEventListener("mousemove", this.resetInactivityTimer);
        window.addEventListener("keydown", this.resetInactivityTimer);
        window.addEventListener("keydown", this.resetInactivityTimer);
        window.addEventListener("scroll", this.resetInactivityTimer);
    },

    beforeDestroy () {
        this.clearInactivityTimer();
        window.removeEventListener("mousemove", this.resetInactivityTimer);
        window.removeEventListener("keydown", this.resetInactivityTimer);
        window.removeEventListener("scroll", this.resetInactivityTimer);
    }
}
</script>