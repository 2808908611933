<template>
   <layout>
       <template v-slot:username>
            <v-card-text>                                                
                <div class="primary--text text-h5 text-center">
                    Admin / PTA Sign in                                                            
                </div>                                            
            </v-card-text>
            <v-alert
                v-if="inActivity"
                text
                outlined
                color="deep-orange"
            >
                You have been signed out due to inactivity. Please sign back in to continue.
            </v-alert>
            <v-form 
                class="pa-5"
                @submit.prevent
            >
                <v-text-field
                label="Username"
                placeholder="Enter username"
                name="id"                                                
                type="text"
                color="primary"
                v-model="formData.name"
                clearable
                autofocus
                v-on:keyup="keyPress"
                >
                    <v-icon
                        slot="prepend"
                        color="primary"
                    >
                        mdi-account
                    </v-icon>
                </v-text-field>
                <div class="text-right mt-4 mb-4">                                                
                    <v-btn 
                        color="primary" 
                        @click="nextStep" 
                        dark
                        block
                    >
                        NEXT
                    </v-btn>
                </div>
            </v-form>
        </template>

        <template v-slot:password>
            <v-form 
                class="pa-5"
                @submit.prevent="submit"
            >
                <v-text-field
                    v-show="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :label="passwordLabel"
                    :placeholder="passwordPlaceholder"
                    :hint="passwordHint"
                    persistent-hint
                    @click:append="show = !show"                                                    
                    name="password"
                    v-model="formData.password"
                    clearable
                    autofocus
                >
                    <v-icon
                        slot="prepend"
                        color="primary"
                    >
                        mdi-key
                    </v-icon>
                </v-text-field>
            

                <div class="text-right mt-4  mb-4">
                    <v-btn 
                        color="primary" 
                        @click="submit" 
                        dark
                        block
                    >
                        LOGIN
                    </v-btn>
                </div>
            </v-form>             
        </template>
   </layout>
</template>

<script>
import Layout from '../layouts/Login';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'AdminLogin',
    components: {
        Layout
    },
    created () {
        console.log(`Inactivity: ${this.inActivity}`)
    },

    data(){
        return {            
            show: false,
            userpassword: '',
            formData: {
                name: '',
                password: '',                
            },
            password: true,
            studentIdQuery: false,
            passwordPlaceholder: 'Enter password',
            passwordLabel: 'Password',
            passwordHint: '',
            windowTitle: 'Enter Password',
            resetPasswordErrors: '',                      
        }
    },         
    props: {
        source: String,
    },
    watch: {
        userpassword: {
            handler(val){
                this.formData.student_id = val;                
            }
        },              
    },
    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay',
            schoolName: 'termReports/getSchoolName',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            getloginErrors: 'auth/getLoginErrors',
            getAuthenticated: 'auth/getAuthenticated',
            getUser: 'auth/getUser',
            step: 'auth/getStep',  
            userAdmin: 'admin/getUserAdmin',
            inActivity: 'auth/getInActivity',                 
        }),
        
    },
    methods: {
        ...mapActions({
            signIn: 'auth/adminSignIn',
        }),

        ...mapMutations({
            setDialog: 'auth/setDialog',            
            setUserId: 'auth/setUserId',            
            setUser: 'form/setUser',
            loginError: 'auth/setLoginErrors',
            setStep: 'auth/setStep', 
            setUserAdmin: 'admin/setUserAdmin',
            //getUser: 'auth/getUser',
        }),

        nextStep(){
            console.log('next step');
            this.loginError('');            
            this.setStep(2);
        },

        keyPress (e) {            
            if(e.keyCode === 13){
                //console.log(e);
                this.nextStep();
            }
        }, 

        async submit () {
            await this.signIn(this.formData);            
            if(this.getAuthenticated){                              
                this.setUser = this.getUser.name[0];
                sessionStorage.setItem("userAdmin", this.getUser.name);
                this.setUserAdmin(this.getUser.name);
                this.$router.replace('/admin/dashboard');
            }                                 
        },

        enterPassword(){
            //console.log(this.formData);
            if(this.formData.name){
                this.loginError('');
                this.step++;
            }
            else{
                this.loginError('Username field empty');
            }
        }        
        
    }
}
</script>

<style scoped>
    .container-background{
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }    

</style>