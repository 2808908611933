<template>
    <v-col
        class="pl-0 py-0"
    >
        <v-row>
            <v-card
                width="700"                
                class="mx-3 mt-3 pa-1 d-flex"
                flat
                                               
            >
                
                 <v-card
                    width="620"
                    flat
                    outlined
                >
                    <v-data-table
                        :headers="headers"
                        :items="table2Records"
                        dense                    
                        fixed-header
                        height="350"
                        disable-pagination
                        hide-default-footer                        
                    >
                        <template v-slot:[`header.subject_id`]="{ header }">
                            <span class="justify-center">{{ header.text }}</span>
                        </template>

                        <template v-slot:[`header.course_mark`]="{ header }">
                            <span class="justify-center">{{ header.text }}</span>
                        </template>

                        <template v-slot:[`header.exam_mark`]="{ header }">
                            <span class="justify-center">{{ header.text }}</span>
                        </template>

                        <template v-slot:item="{ item }">
                             <subject-row 
                                v-bind:item="item"
                                v-bind:formLevel="formLevel"
                                v-bind:term="currentTerm"
                                v-on:update-average="getAverage"
                            ></subject-row>                       
                        </template>
                        
                    </v-data-table>
                </v-card >

                <v-card
                    width="76"
                    flat
                    outlined
                >
                    <v-data-table
                        :headers="headersSubjects"
                        :items="studentSubjects"
                        dense
                        fixed-header
                        height="350"
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td style="max-width: 1px; white-space: nowrap; overflow:hidden; text-overflow: ellipsis; ">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                                :class="classStudentSubjects(item)"
                                            > 
                                                {{ item.abbr}} 
                                            </span>
                                        </template>
                                        <span>{{ item.title}}</span>
                                    </v-tooltip>                                   
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
                   
            </v-card>
        </v-row>

        <v-row>
            <v-col class="py-2">         
                <v-card                                
                    class=""
                    flat
                    
                >
                    <div>
                        <v-card flat width="620" class="d-flex justify-space-between">
                            <v-row>
                                <v-col class="pb-0 d-flex justify-center">
                                    <v-btn                        
                                        outlined
                                        small
                                        color="primary"
                                        @click="classMarkSheet"
                                        
                                    >
                                        <span style="font-size: 0.6rem" >Class Mark Sheet</span>
                                    </v-btn>
                                </v-col>
                                
                                <v-col class="pb-0 d-flex justify-center">
                                    <v-btn                        
                                        outlined
                                        small
                                        color="primary"
                                        @click="rankSheet"
                                        width="100"
                                    >
                                        <span style="font-size: 0.6rem">Rank Sheet</span>
                                    </v-btn>
                                </v-col>

                                <v-col class="pb-0 d-flex justify-center">
                                    <v-btn                        
                                        outlined
                                        small
                                        color="primary"
                                        @click="enterMarks"
                                        width="100"
                                    >
                                        <span style="font-size: 0.6rem">Enter Marks</span>
                                    </v-btn>
                                </v-col>

                                <v-col class="pb-0 d-flex justify-center">
                                    <v-btn                        
                                        outlined
                                        small
                                        color="red"
                                        width="100"
                                        @click="closeEditViewTermReports"
                                    >
                                        <span style="font-size: 0.6rem">Close Form</span>
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-card>    
                    </div>
                </v-card>

                <div class="mt-3">
                    <v-card flat width="620" class="d-flex justify-space-between">
                        <v-row>
                            <v-col class="d-flex justify-space-between pt-0">
                                <v-btn                        
                                    outlined
                                    small
                                    color="primary"
                                    @click="searchStudent"
                                    width="60"
                                >
                                    <span style="font-size: 0.6rem">Search</span>
                                </v-btn>
                                <v-btn                        
                                    outlined
                                    small
                                    color="primary"
                                    @click="nextClass"
                                    width="60"
                                >
                                    <div class="d-flex flex-column">
                                    <span style="font-size: 0.6rem">Next</span>
                                    <span style="font-size: 0.6rem">Class</span>
                                    </div>
                                </v-btn>
                            </v-col>

                            <v-col class="d-flex justify-center pt-0">
                                <v-btn                        
                                    outlined
                                    small
                                    color="primary"
                                    @click="displayReportCard"
                                    width="100"
                                >
                                    <span style="font-size: 0.6rem">Print One</span>
                                </v-btn>
                            </v-col>

                            <v-col class="d-flex justify-center pt-0">
                                <v-btn                        
                                    outlined
                                    small
                                    color="primary"
                                    @click="displayReportCardsClass"
                                    width="100"
                                >
                                    <span style="font-size: 0.6rem">Print Class</span>
                                </v-btn>
                            </v-col>

                            <v-col>
                            </v-col>
                        </v-row>
                    </v-card>    
                </div>
            </v-col>    
        </v-row>       

        <v-row
            class="my-4"
        >            
            <v-card flat width="620" class="d-flex justify-end">
                <v-col class="pa-0 mr-4 flex-grow-0">
                    <v-card
                        height="89"
                        width="90"
                        flat
                        outlined
                        class="ml-3"
                    >
                        <v-card-title
                            class="pb-0 d-flex justify-center"
                            style="font-size:0.7rem"
                        >
                            Average
                        </v-card-title>
                        <v-card-text
                            class="text-center text-h6 font-weight-bold px-0 primary--text"                        
                        >
                            <span :style="styleFontSize">{{ getStudentAverage }} </span>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col class="pa-0 flex-grow-0">
                    <v-card
                        flat
                        width="270"
                    >
                        <v-row 
                            class="pl-3 ml-0 mb-1"
                            id="label-form-class"
                        >
                            
                            <v-card 
                                width="80" 
                                flat
                                class="ml-auto mr-3"
                                style="font-size:0.55rem; text-align: center"
                            >
                                All Classes
                            </v-card>
                            <v-card 
                                width="75" 
                                flat 
                                style="font-size:0.55rem"                           
                            >
                                Current Class
                            </v-card>                       
                        </v-row>

                        <v-row class="pl-3 ml-0 mb-2">
                            <v-card 
                                flat 
                                style="font-size:0.7rem"
                                class="mr-2"
                                width="82"
                            >
                                Possible Att.
                            </v-card>
                            <v-card 
                                width="80" 
                                flat
                                class="mr-2"
                            >
                                <v-text-field                                
                                    v-model="possibleAttendanceAll"
                                    outlined
                                    hide-details
                                    dense
                                    style="font-size:0.7rem"
                                    type="number"
                                    @blur="changePossibleAttendance('All')"
                                    :disabled="!admin"
                                ></v-text-field>
                            </v-card>
                            <v-card 
                                width="80" 
                                flat                            
                            >
                                <v-text-field
                                    v-model="studentRecord.possible_attendance"
                                    outlined
                                    hide-details
                                    dense
                                    style="font-size:0.7rem"
                                    type="number"
                                    @blur="changePossibleAttendance(studentRecord.class_id)"
                                    :disabled="!admin"
                                ></v-text-field>
                            </v-card>                       
                        </v-row>

                        <v-row class="pl-3 ml-0 mb-2">
                            <v-card 
                                flat 
                                style="font-size:0.7rem"
                                class="mr-2"
                                width="82"
                            >
                                School Reopens
                            </v-card>
                            <v-card 
                                width="80" 
                                flat
                                class="mr-2"
                            >                            
                                <date-picker 
                                    v-on:change-date="newTermDate($event,'All')"
                                    :disabled="!admin"                                
                                ></date-picker>                           
                            </v-card>
                            <v-card 
                                width="80"
                                style="font-size:0.7rem" 
                                flat                            
                            >
                                <date-picker 
                                    v-on:change-date="newTermDate($event, studentRecord.class_id)"
                                    v-bind:date="studentRecord.new_term_beginning"
                                    :disabled="!admin"
                                ></date-picker> 
                            </v-card>                       
                        </v-row>

                        <v-row class="pl-3 ml-0">
                            <v-card 
                                flat 
                                style="font-size:0.7rem"
                                class="mr-2"
                                width="82"
                            >
                                Change Class
                            </v-card>
                            <v-card 
                                width="80" 
                                flat
                                class="mr-2"
                            >
                                <v-select
                                    outlined
                                    hide-details
                                    dense
                                    :items="classes"
                                    class="change-class"
                                    style="font-size:0.6rem"
                                    v-model="studentRecord.class_id"
                                    @change="changeClass"
                                    :disabled="!admin"
                                ></v-select>
                            </v-card>
                            <v-card 
                                width="80" 
                                flat                            
                            >
                                <v-btn                                
                                    color="primary"
                                    x-small
                                    height="30"
                                    block
                                    @click="deleteRecord"
                                    :disabled="!admin"                                
                                >
                                    Delete
                                </v-btn>
                            </v-card>                       
                        </v-row>

                    </v-card>
                </v-col>
            </v-card>
        </v-row>

        <v-overlay
            absolute
            :value="overlaySearch"
            color="secondary"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                            clearable
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headersStudents"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>        
    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DatePicker from './DatePicker.vue';
import SubjectRow from './EditViewTermReportsSubjectRow';
export default {
    components: {        
        DatePicker,
        SubjectRow,
    },
        
    data: () => ({
        headers: [],
        headersSubjects:[],
        headersStudents: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        subjectRecords: [],        
        studentAverage: '',
        studentPasses: null,
        studentDistinctions: '-',
        imageSrc: null,        
        value: ' ', 
        rules: [],
        isValid: true, 
        record: {},
        subjectRecord: [],
        disabled: true,
        displayAverage: false,
        displayGrade: false,
        displayPasses: false,
        displayDistinctions: false,
        formLevel: null,
        btnClass: 'text-caption pa-2  white--text',
        codedComment: null,
        styleFontSize: '0.7rem',
        classes: [],
        newTermBeginningAll: '', 
        overlaySearch: false, 
        expand: false, 
        search: null,  
        options: {
            itemsPerPage: -1,
        },
        possibleAttendanceAll: null,
                        
    }),

    watch: {       
        table2Records: {
            deep: true,
            handler () {                                           
                this.getAverage();
                this.setTableHeaders();
            }
        },

        studentRecord: {
            handler(val){                
                this.formLevel = val.form_level;
                if(
                    this.formTeacherClass.indexOf(this.studentRecord.class_id) === -1 && 
                    !this.admin &&
                    this.deanFormClasses.indexOf(this.studentRecord.class_id) === -1
                ){
                    //console.log('Not the form Teacher');
                    this.disabled = true;
                }
                else{
                    //console.log('Assigned Form Teacher');
                    this.disabled = false;
                }
                //this.setTableHeaders();
                //console.log(this.selectedFormClass);
            }
        },
        
        formClasses: {
            handler () {
                this.setFormClasses();
            }
        }
    },

    computed: {
        ...mapGetters({
            sheet: 'termReports/getCommentSheet',
            formTeacherComments: 'termReports/getFormTeacherComments',
            table2Records: 'termReports/getEditViewTermReportsTable2Records',            
            studentRecord: 'termReports/getSelectedStudentRecord',
            formTeacherClass: 'termReports/getFormTeacherClass',
            formClasses: 'termReports/getFormClasses',
            currentTerm: 'termReports/getCurrentTerm',
            currentYear: 'termReports/getCurrentYear',
            pagination: 'termReports/getPagination',
            selectedFormClass: 'termReports/getSelectedFormClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            studentSubjects: 'termReports/getEditViewTermReportsStudentSubjects',
            admin: 'auth/getAdmin',
            menuOptions: 'admin/getMenuOptions',
            subjectWeightings: 'termReports/getSubjectWeightings',
            deanFormClasses: 'termReports/getDeanFormClasses', 
        }),       

        getStudentAverage () {
            return isNaN(this.studentAverage) ? this.studentAverage : this.studentAverage + ' %';
        },
        
        
    },
    methods: {
        ...mapMutations({
            setCommentSheet: 'termReports/setCommentSheet',
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setPagination: 'termReports/setPagination',
            overlay: 'termReports/setEditViewTermReportsOverlay',
            loading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setBottomSheetModel: 'termReports/setBottomSheetModel',
            setBottomSheetTitle: 'termReports/setBottomSheetTitle',
            setChangeClassRecord: 'admin/setChangeClassRecord',            
            setSnackbar: 'admin/setSnackbar',
            setPossibleAttendance: 'admin/setPossibleAttendance',
            setNewTermBeginning: 'admin/setNewTermBeginning',
            setDialogDelete: 'admin/setDialogDelete',
            setSelectedTable1Record: 'admin/setSelectedTable1Record',
            setExpandTerms: 'termReports/setExpandTerms',
            setSrc: 'termReports/setSrc',
            setEnterMarks: 'termReports/setEnterMarks',
            setEditViewTermReports: 'termReports/setEditViewTermReports',
            setMarkSheetOverlay: 'termReports/setMarkSheetOverlay',            
            setMarkSheetLoading: 'termReports/setMarkSheetLoading',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setExpand: 'termReports/setEditViewTermReportsExpand', 
            setLoading: 'termReports/setEditViewTermReportsLoading', 
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setTable1Records: 'termReports/setTable1Records',
        }),

        ...mapActions({
            postTable1Record: 'termReports/postTable1Record',
            getTable1Record: 'termReports/getTable1Record',
            setErrorResponse: 'termReports/setErrorResponse',
            getTable2Records: 'termReports/getTable2Records',
            postChangeClass: 'admin/changeClass',
            postPossibleAttendance: 'admin/postPossibleAttendance',
            postNewTermBeginning: 'admin/postNewTermBeginning',
        }),

        setFormClasses () {            
            this.formClasses.forEach(value => {
                value.items.forEach(item => {
                    this.classes.push(item.title);
                })
            });
        },

        getAverage(){
            this.styleFontSize = null;                   
            let recordCount = 0; 
            let totalMarks = 0;
            this.table2Records.forEach(record => {
                recordCount++; 


                if(record.term != 2 ) {
                    totalMarks += (record.exam_mark+record.course_mark)/2;                     
                }
                else if(record.term == 2 && this.studentRecord.form_level <= 4 ) {
                    totalMarks += record.course_mark;                   
                }
                else if(record.term == 2 && this.studentRecord.form_level >= 5) {
                    totalMarks += (record.exam_mark+record.course_mark)/2;
                }
                
            })
            
            //console.log(`Total: ${totalMarks}`);
            if(recordCount != 0 ){               
                this.studentAverage =  (totalMarks / recordCount).toPrecision(3);
            }
            else if(recordCount == 0) this.studentAverage = '--';
           
            
        },
        
        saveTeacherComment(){
            //console.log('saving...');
            //console.log(this.$refs.form.validate());
            this.updateRecord();
        },

        openBottomSheet(model){
            this.setBottomSheetModel(model); 
            if(model == 'comments'){
                this.setBottomSheetTitle('Form Teacher Comments');
            }
            else{
                this.setBottomSheetTitle('General Remarks: Conduct');
            }
            if(!this.disabled) this.setCommentSheet(true);
            //console.log(`sheet: ${ this.sheet }`);
        },

        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //this.studentRecord.lib_comment = this.codedComment.id;           
            //console.log(this.studentRecord);
            try{
                await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                //console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }            
        },

        setTableHeaders () {
            this.headers = [
                {text: 'Code', align: 'center', value: 'subject_id', width: '60', sortable: false},
                {text: 'Subject', align: 'start', value: 'abbr', width: '90', sortable: false},
                {text: 'Course', align: 'start', value: 'course_mark', width: '80', sortable: false},
                {text: 'Exam', align: 'start', value: 'exam_mark', width: '80', sortable: false},
                {text: 'Application', align: 'center', value: 'app1', width: '70', sortable: false},
                {text: 'Conduct', align: 'center', value: 'con1', width: '70', sortable: false},
                {text: 'Comment1', align: 'start', value: 'coded_comment', width: '100', sortable: false},
                {text: 'Comment2', align: 'start', value: 'coded_comment_1', width: '100', sortable: false},               
                {text: '', align: 'start', value: 'actions', sortable: false}               
            ];                    

            this.headersSubjects = [{text: 'Subjects', value: 'abbr', sortable:false}]
             
        },

        async changeClass () {
            this.overlay(true);
            this.loading(true);            
            this.setChangeClassRecord({
                student_id: this.studentRecord.student_id,
                class_id: this.studentRecord.class_id
            })
            try {
                let response = await this.postChangeClass();
                this.setSnackbar({
                    text: `Class changed to ${this.studentRecord.class_id}`,
                    display: true,
                    color: 'primary',
                });               
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSnackbar({
                    text: `Error occured`,
                    display: true,
                    color: 'red',
                });              
            }            
            this.overlay(false);
            this.loading(false);
        },

        async changePossibleAttendance (formClass) {
            this.loading(true);

            let snackbar = {};

            if(formClass == 'All'){
                this.studentRecord.possible_attendance = this.possibleAttendanceAll;
                this.setPossibleAttendance({
                    possible_attendance: this.possibleAttendanceAll,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `Possible attendance set to ${this.possibleAttendanceAll} for all classes.`
                
            }
            else{
                this.setPossibleAttendance({
                    possible_attendance: this.studentRecord.possible_attendance,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                })
                snackbar.text = `Possible attendance set to ${this.studentRecord.possible_attendance} for ${this.studentRecord.class_id}.`
            }

            try {
                await this.postPossibleAttendance();
                this.updateTable1Record();
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
                this.setSnackbar(snackbar);
            }
            
            this.overlay(false);
            this.loading(false);
        },

        async updateTable1Record () {
            const { data } = await this.getTable1Record();
            console.log(data)
            this.setTable1Records(data.data);
        },

        async newTermDate (e, formClass) {
            this.overlay(true);
            this.loading(true);
            this.studentRecord.new_term_beginning = e;

            let snackbar = {};

            if(formClass == 'All'){
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: null,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for all classes.`
            }
            else{
                this.setNewTermBeginning({
                    new_term_beginning: e,
                    class_id: this.studentRecord.class_id,
                    year: this.studentRecord.year,
                    term: this.studentRecord.term,
                });
                snackbar.text = `New Term Beginning set to ${e} for ${formClass}.`
            }
            // console.log(`Date: ${e}, class: ${formClass}`);

            try {
                let response = await this.postNewTermBeginning();
                console.log(response);
                snackbar.color = 'primary';
                snackbar.display = true;
                this.setSnackbar(snackbar);  
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                snackbar.color= 'red';
                snackbar.display = true;
                snackbar.text = 'An Error Has Occured';
            }

            this.overlay(false);
            this.loading(false);
            
        },

        deleteRecord () {
            // console.log(this.table2Records);
            this.setSelectedTable1Record(this.studentRecord);
            if(this.table2Records.length != 0){
                this.setDialogDelete({
                    display: true,
                    text: 'Cannot delete record with existing subject records. Please delete all subject records first.',
                    cancel: false,
                    deleteSubject: false,
                    deleteStudent: false,
                    ok: true
                })
            }
            else{
                console.log(this.studentRecord);
                this.setDialogDelete({
                    display: true,
                    text: `Delete ${this.studentRecord.first_name} ${this.studentRecord.last_name} ?`,
                    cancel: true,
                    deleteSubject: false,
                    deleteStudent: true,
                    ok: false
                })
            }
        },

        classMarkSheet () {
            this.overlay(true);
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });

            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/mark-sheet',
                year: this.currentYear,
                term: this.currentTerm,
                classId: this.studentRecord.class_id,
                report: 'mark-sheet'               
            });

        },

        rankSheet () {            
            this.overlay(true);
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });
            
            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/rank-sheet',
                year: this.currentYear,
                term: this.currentTerm,
                classId: this.studentRecord.class_id,
                report: 'rank-sheet'               
            });           
           
        },
        
        classStudentSubjects (item) {
            // console.log(item);            
            if(item.entered == 1) return "font-weight-bold primary--text";
            return "font-weight-bold red--text";
        },

        enterMarks () {
            if(this.admin){
                 Object.keys(this.menuOptions).forEach(key => {
                    this.menuOptions[key] = false;
                })
                this.menuOptions["enterMarks"] = true;
            }
            else{                
                this.setEnterMarks(true);
                this.setEditViewTermReports(false);
                this.setMarkSheetOverlay(true);                    
                this.setMarkSheetLoading(true); 
            }
        },

        displayReportCard () {
            this.overlay(true);
            
            this.$nextTick(() => {
                this.setExpandTerms(true);
            });
            
            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/report-card',
                studentId: this.studentRecord.student_id,
                year: this.studentRecord.year,
                term: this.studentRecord.term,
                report: 'report-card'               
            });  
        },

        displayReportCardsClass () {
            this.overlay(true);

            this.$nextTick(() => {
                this.setExpandTerms(true);
            });
            
            this.setSrc({
                baseUrl: process.env.VUE_APP_API_URI  + '/api/report-card',
                studentId: this.studentRecord.student_id,
                year: this.studentRecord.year,
                term: this.studentRecord.term,
                report: 'report-card',
                classId: this.studentRecord.class_id,               
            });  
        },

        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        searchStudent(){
            this.overlaySearch = true;
            setTimeout(() => {
                this.expand = true;
            })
        },

        cancelSearch(){
            this.expand = false;
            setTimeout(() => {
                this.overlaySearch = false;
            }, 600);
        },

        viewRecord(item){
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlaySearch = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },

        async recordNavigation(pagination){
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                const response = await this.getTable2Records();
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);           
        },

        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },

        closeEditViewTermReports () {
            this.setOverlay(true);
            this.setExpand(true);
        },
    }
}
</script>

<style scoped>    

    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    .v-btn__content{
        font-size: 0.8em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
    }

    ::v-deep .v-select__selections{
        height: 30px;
        /*padding: 0 !important;*/
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-text-field__slot textarea{
        line-height: 1.2rem;
        font-size: 0.7rem;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        padding: 0 6px;
        font-size: 0.7rem;
        text-align: center;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 6px;
        font-size: 0.75rem;
    }

    ::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 22px;
    }

    ::v-deep .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th {
        height: 22px;
    }

    ::v-deep textarea {
        padding-top: 8px;
    }

    ::v-deep .v-input input{
        height: 25px;
    }

    /* ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 5px;
    } */

    ::v-deep .change-class .v-input__icon{
        min-width: 10px;
        width: 10px;
    }

    ::v-deep .v-list-item__title{
        min-width: 100px;
    }

    ::v-deep .v-select.v-input--dense .v-select__selection--comma{
        width: 50px;
        text-align: center;
    }

    ::v-deep .v-textarea.v-text-field--enclosed textarea{
        margin-top: 5px;
    }

    ::v-deep #label-form-class{
        position: absolute;
        top: -15px;
        right: 0;
    }

    ::v-deep .v-text-field--filled .v-label--active {
        transform: translateY(-20px) scale(0.75);
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center;
    }
</style>