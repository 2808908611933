<template>
 <div 
    class="d-flex flex-column" 
    :style="{ width: dataField.width }"
 >
    {{ dataField.text }}
    <v-menu
        v-model="menu"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                x-small
                depressed
                width="65"
                class="my-3 pa-3"
                v-bind="attrs"
                v-on="on"
                :disabled="!dataField.customFilter"
                color="primary"

            >
                <!-- <v-icon
                    left
                    x-small
                    v-if="filter"
                >
                    mdi-filter
                </v-icon> -->
                Filter
            </v-btn>
        </template>
        <v-card>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="filterItems"
              hide-default-footer
              show-select
              item-key="id"
              height="50vh"
              fixed-header
              :items-per-page="itemsPerPage"
          >
              <template v-slot:no-data>
                  No Available Filters
              </template>

          </v-data-table>

          <v-card-actions>
            <v-btn small depressed @click="menu = false">
                Close
            </v-btn>
            <v-btn small depressed color="primary" @click="applyFilter">
                Apply Filter
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-menu>
  </div>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
  export default {
    props: {
      dataField: Object,
    },

    data () {
      return {
        headers: [],
        selected: [],
        filterItems: [],
        itemsPerPage: -1,
        menu: false,
        filter: false,
        items: [],
      }
    },

    watch: {
      dataField: {
        handler() {
          this.initialize();
          // console.log(val)
        },
        deep: true,
      },

      studentDataFilters: {
        handler (val) {
          if(val && this.dataField.customFilter && this.dataField.value !== 'class_id'){
            this.updateFilterItems(val);
          }
        },
        deep: true
      },

      // 'dataField.filterHeaders' : {
      //   handler (val) {
      //     console.log(this.datafield, val)
      //   }
      // }
    },

    created () {
      // console.log(this.dataField)
      this.initialize();
    },

    computed: {
      ...mapGetters({
        studentsCurrent: 'admin/getStudentsCurrent',
        studentDataFilters: 'admin/getStudentDataFilters',
      })
    },

    methods: {
      ...mapMutations({
        setStudentsFiltered: 'admin/setStudentsFiltered',
        setStudentDataFilters: 'admin/setStudentDataFilters',
      }),

      ...mapActions({
        //
      }),

      async initialize () {
        const { 
          customFilter, 
          filterHeaders, 
          filterItems, 
          selected, 
        } = this.dataField;
        // console.log(filterHeaders)
        
        //if data field does not contain a custom filter
        //then ignore
        if(!customFilter) return;

        this.items = filterItems;
        const items = this.copyObjectArray(filterItems);
        
        if(
          filterItems.length != 0 &&
          'class_ids' in filterItems[0]
        ){
          this.mapItemsSum(items);
        }
      
        this.unshiftBlanks(items)
        this.filterItems = items;
        this.headers = [...filterHeaders];
        this.selected = [...selected];
      },

      unshiftBlanks (items) {
        let index = items.findIndex(obj => !obj.value);
        if(index !== -1){
          let element = items.splice(index,1)[0];
          items.unshift(element)
        }
      },

      copyObjectArray (array) {
        return array.map(value => {
          const valueCopy = {};
          for(let key in value){
            if(!Array.isArray(value[key])){
              valueCopy[key] = value[key]
            }
          }
          let classIdsCopy = [];
          if(value.class_ids){
            classIdsCopy = value.class_ids.map(classId => {
              return {...classId}
            })
          }
          valueCopy['class_ids'] = classIdsCopy;
          return valueCopy;
        });
      },

      mapItemsSum (items) {
        items.map(item => {
          let itemText = item.text || '(Blanks)';
          item.value = item.text;
          const sum = this.filterItemCount(item);
          item.text = `${ itemText } (${ sum })`
        })
      },

      updateFilterItems (filters) {
        //runs when class is changed filters updates
        //to show how much exists in the selected classes
        console.log('updating filter items...')
        //if field has no class_id then dont update
        if(!('class_id' in filters)) return;
        
        const items = this.copyObjectArray(this.items)
        
        items.map(item => {
          // let found = false;
          
          item.class_ids.forEach(element => {
            const found = filters.class_id.some(filter => {
              if(filter.id === element.class_id){
                return true;
              }
            })
            if(!found) element.count = 0;
          })

         
          return item;
        })

        this.mapItemsSum(items)
       
        this.unshiftBlanks(items);
        this.filterItems = items;
        // console.log(items)
      },

      filterItemCount (item) {
        const initialValue = 0;
        return item.class_ids.reduce((accumulator, currentValue) => 
          accumulator + currentValue.count, initialValue,
        )

      },

      applyFilter () {
        console.log('applying filter...')
        const filters = {...this.studentDataFilters};
        const { value: field } = this.dataField;
        filters[field] = this.selected;
        this.setStudentDataFilters(filters);
        this.setStudentsFiltered([])
        let studentsFiltered = this.studentsCurrent;
        Object.keys(this.studentDataFilters).forEach(key => {
          studentsFiltered = studentsFiltered.filter(student => 
            this.studentDataFilters[key].some(filterItem => filterItem.value === student[key] )
          )
        })
        this.setStudentsFiltered(studentsFiltered);
      }
    }

  }
</script>