<template>
    <v-app>
        <v-app-bar 
            app
            color="primary"
            dark
            clipped-left
            elevation="0"
            height="50"
        >
            <div class="d-flex align-center secondary--text font-weight-bold">
                <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="../assets/logo.png"
                transition="scale-transition"
                width="40"
                />
                {{ schoolName}}
                
            </div>
            <v-spacer></v-spacer>
            <Logout/>
        </v-app-bar>

        <v-navigation-drawer
            :mini-variant.sync="mini_md"            
            app
            clipped
            color="primary"            
            permanent
            width="200"
            class="d-md-block"
        >         
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="secondary--text font-weight-light font-italic">
                        Welcome
                    </v-list-item-title>
                    <v-list-item-subtitle class="secondary--text text-h6 font-weight-bold">
                        {{ userAdmin }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            
            <AdminNavigation/>            
        </v-navigation-drawer>       

        <v-main class="grey lighten-5">
            <InactivityTimer/>
            <AdminDashboard  
                v-if="menuOptions.dashboard"
                v-on:progress-type="setProgressType"
            />
            <TeacherLessons v-if="menuOptions.employees"/>
            <Subjects v-if="menuOptions.studentSubjects"/>
            <Students v-if="menuOptions.students"/>
            <PostTermReports v-if="menuOptions.postReports"/>
            <edit-view-term-reports v-if="menuOptions.termReports"></edit-view-term-reports>
            <enter-marks v-if="menuOptions.enterMarks"></enter-marks>
            <reports v-if="menuOptions.reports"></reports>
            <mark-book v-if="menuOptions.markBook"></mark-book> 
            <AdminAttendanceStudent v-if="menuOptions.attendanceStudent" />     
            <StudentRegistration v-if="menuOptions.registration" />      
        </v-main>

        <v-overlay :value="progressOverlay">
            <v-card 
                light
                v-if="linearProgress"
                width="300"
                class="pa-4"
            >
                <v-card-title>Registering Students...</v-card-title>
                <v-progress-linear                    
                    v-model="progress"
                    color="primary"
                    background-color="primary"
                    height="25"
                    :buffer-value="bufferValue"
                    stream
                >
                    <strong>{{ Math.ceil(progress) }} %</strong>
                </v-progress-linear>
            </v-card>
           
            
            <v-progress-circular
                v-else
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
            
        </v-overlay>

        <sweet-modal
            :icon="modalIcon"
            ref="modal"
            overlay-theme="dark"
            :title="modalTitle"
            v-on:close="modalClose"
        >
            {{ modalMessage }}
        </sweet-modal>

        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"                       
        >
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn                    
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import AdminNavigation from './AdminNavigation';
import AdminDashboard from './AdminDashboard';
import TeacherLessons from './AdminEmployees';
import Subjects from './AdminSubjects';
import Students from './AdminStudents';
import PostTermReports from './AdminPostTermReports';
import { SweetModal } from 'sweet-modal-vue';
import { mapGetters, mapMutations } from 'vuex';
import EditViewTermReports from './EditViewTermReports.vue';
import EnterMarks from './EnterMarks';
import Reports from './AdminReports';
import MarkBook from './MarkBook.vue'
import AdminAttendanceStudent from './AdminAttendanceStudent';
import StudentRegistration from './AdminStudentRegistration.vue';
import InactivityTimer from './InactivityTimer.vue';

export default {
    name: 'Admin',
    components: {
        Logout,
        AdminDashboard,        
        TeacherLessons,        
        AdminNavigation,
        Subjects,        
        SweetModal,
        Students,
        PostTermReports,
        EditViewTermReports,
        EnterMarks,
        Reports,
        MarkBook,
        AdminAttendanceStudent,
        StudentRegistration,
        InactivityTimer,
    },
    created () {
        this.initialize();
    },
    computed: { 
        ...mapGetters({
            getUser: 'termReports/getUser',
            schoolName: 'termReports/getSchoolName',
            employees: 'admin/getEmployees',
            studentSubjects: 'admin/getStudentSubjects',
            modal: 'admin/getModal',
            modalTitle: 'admin/getModalTitle',
            modalIcon: 'admin/getModalIcon',
            modalMessage: 'admin/getModalMessage',
            progressOverlay: 'admin/getProgressOverlay',
            menuOptions: 'admin/getMenuOptions', 
            snackbar: 'admin/getSnackbar',
            progress: 'admin/getTermRegistrationProgress', 
            userAdmin: 'admin/getUserAdmin',                 
        }),           
    },
    
    watch: {
        modal:{
            handler(value){
                console.log('opening modal..')
                if(value) this.openModal();
            }
        },
        
    },

    data: () => ({                
        dialog: false,
        mini_md: false,        
        linearProgress: false,
        progressInterval: null,
        progressComplete: false,
        bufferValue: 10,
    }),

    methods: {
        ...mapMutations({
            setModal: 'admin/setModal',
            setMenuOptions: 'admin/setMenuOptions', 
            setOverlay: 'admin/setProgressOverlay',           
        }),        

        openModal () {            
            this.$refs.modal.open();
        },

        modalClose () {
            this.setModal(false);
        },

        initialize () {
            
            if(this.userAdmin != 'PTA'){
                this.setMenuOptions({
                    dashboard: true,
                })
            }
        },
        
        setProgressType (type) {
            if(type == 'linear')
            this.linearProgress = true;
            else this.linearProgress = false;            
        },
      
    }
}
</script>