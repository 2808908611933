<template>
    <tr>
        <td>{{ lessonRecord.subjectId }}</td>
        <td> 
            <v-autocomplete
                v-model="lessonRecord.newSubjectId"
                :items="subjects"
                item-text="title"
                item-value="id"
                outlined
                dense
                hide-details
                @blur="updateLesson"
            ></v-autocomplete>
        </td>
        <td>
            <v-autocomplete
                v-model="selectedClasses"
                :items="formClasses"
                outlined
                dense
                hide-details
                multiple
                chips
                deletable-chips
                small-chips
                @blur="updateLesson"
            ></v-autocomplete>
        </td>
        <td> 
            <v-icon
                small
                @click="deleteSelectedLesson"
                tabindex="-1"
            >
                mdi-delete
            </v-icon>
        </td>
        <td>            
            <v-progress-circular
                indeterminate
                size="16"
                width="3"
                v-if="saveStatus.saving"
            ></v-progress-circular>
            <v-icon
                small
                color="green" 
                v-else-if="saveStatus.saved"
                class="mr-2"
            >
                mdi-check-all
            </v-icon>
            <v-icon
                small
                color="red"
                v-else-if="saveStatus.saveError"
            >
                mdi-alert-circle
            </v-icon>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        lesson: Object,
        lessonClasses: Array,
        lessonSubjects: Array,
        index: Number,
    },

    created () {
        this.initialize();
    },

    data: () => ({
        subjects: [],
        formClasses: [],
        lessonRecord: {},
        selectedClasses: [],
        saveStatus: {
            saving: false,
            saved: false,
            saveError: false,
        },
        selectedSubject: null,
    }),

    computed: {
        ...mapGetters({
            academicYearId : 'admin/getAcademicYearId',
            teacherLessons :  'admin/getTeacherLessons'          
        }),
    },

    watch: {
        lessonRecord: {
            handler (val) {
                if(!val.subjectId){
                    this.lessonRecord.subjectId = this.lessonRecord.newSubjectId
                }
            },
            deep: true,
        },
    },

    methods: {
        async initialize(){
            this.subjects = [...this.lessonSubjects];
            this.formClasses = [...this.lessonClasses];
            // this.lessonRecord = {...this.lesson};
            this.lessonRecord = this.copyObject(this.lesson);
            // console.log(this.lessonRecord)
            this.selectedClasses = this.lesson?.formClasses;
            this.lessonRecord.newSubjectId = this.lesson.subjectId;
            this.lessonRecord.academicYearId = this.academicYearId;
            
            this.selectedSubject = {
                id: this.lessonRecord.subjectId,
                title: this.lessonRecord.subject,
            }
        },

        copyObject (obj) {
            let objCopy = {};
            for(let key in obj) {
                if(!Array.isArray(obj[key])){
                    objCopy[key] = obj[key];
                }else{
                    objCopy[key] = [...obj[key]]
                }
            }
            return objCopy;
        },

        ...mapMutations({
            setPostLesson: 'admin/setPostLesson',
            setDeleteLesson: 'admin/setDeleteLesson',
            setTeacherLessons: 'admin/setTeacherLessons',
            setOverlay: 'admin/setLessonOverlay',
        }),

        ...mapActions({
            postLesson: 'admin/postLesson',
            deleteLesson: 'admin/deleteLesson',
            getTeacherLessons: 'admin/getTeacherLessons'
        }),

        updateLesson(){
            if(
                this.selectedClasses.length == 0 ||
                !this.lessonRecord.newSubjectId
            )
            {
                //fields incomplete
                return;
            }
            
            this.submitLesson();                
        },

        async submitLesson(){
            this.setSaveStatus()
            this.lessonRecord.formClasses = this.selectedClasses;
            let subjectRecord = this.subjects.find(
                subject => subject.id === this.lessonRecord.newSubjectId
            )
            this.lessonRecord.subject = subjectRecord?.title;
            this.setPostLesson(this.lessonRecord);
            try {
                await this.postLesson();
                this.setSaveStatus('saved')
                this.$emit(
                    'update-lessons', 
                    { 
                        index: this.index, 
                        lesson: this.lessonRecord, 
                        deleteLesson: false 
                    }
                );
               
            } catch (error) {
                if(error.response) console.log(error.response)
                console.log(error);
                this.setSaveStatus('saveError')
            }  
        },

        async deleteSelectedLesson(){ 
            this.setOverlay(true);
            if(!this.lessonRecord.subjectId && this.lessonRecord.formClasses.length == 0){
                //empty record
                this.$emit(
                    'update-lessons', 
                    { 
                        index: this.index,
                        lesson: null,
                        deleteLesson: true
                    }
                )
                setTimeout(() => {
                    this.setOverlay(false)
                },500)
                return;
            } 
            
            this.setDeleteLesson(this.lessonRecord);
            
            try {
                await this.deleteLesson();
                this.$emit(
                    'update-lessons', 
                    { 
                        index: this.index, 
                        lesson: null, 
                        deleteLesson: true 
                    }
                );
                
               
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.saveStatus('saveError')
            }
            this.setOverlay(false)
        },

        setSaveStatus (status = 'saving') {
            for(let key in this.saveStatus){
                this.saveStatus[key] = false;
                if(key == status) this.saveStatus[key] = true;
            }
            
        }
    }
}
</script>