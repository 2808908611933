import { render, staticRenderFns } from "./EnterMarksSheetRecord.vue?vue&type=template&id=d53f009a&scoped=true&"
import script from "./EnterMarksSheetRecord.vue?vue&type=script&lang=js&"
export * from "./EnterMarksSheetRecord.vue?vue&type=script&lang=js&"
import style0 from "./EnterMarksSheetRecord.vue?vue&type=style&index=0&id=d53f009a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d53f009a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VProgressCircular,VSelect,VTextField,VTooltip})
