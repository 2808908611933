<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          small
        >
            <v-icon left>mdi-table-column-plus-after</v-icon>
            Add Data Columns
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Data Columns
        </v-card-title>

        <v-card-text>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="dataFieldsFilter"
                item-key="value"
                show-select
                hide-default-footer
                :items-per-page="itemsPerPage"
                height="60vh"
                fixed-header
            >
            </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="dialog = !dialog"
            class="mr-4"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="close"
          >
            Add Columns
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
  export default {
    created () {
      this.initialize();
    },

    props: {
      dataFields: Array,
    },

    data () {
      return {
        dialog: false,
        selected: [],
        headers: [
            {
                text: 'Student Data Columns',
                value: 'text'
            }
        ],
        itemsPerPage: -1,
        dataFieldsFilter: [],
      }
    },

    computed: {
        ...mapGetters({
            studentDataHeaders: 'admin/getStudentDataHeaders',
            userAdmin: 'admin/getUserAdmin',
        })

    },

    methods: {
        ...mapMutations({
            setStudentDataFields: 'admin/setStudentDataFields',
            setStudentDataHeaders: 'admin/setStudentDataHeaders',
        }),

        initialize () {
          this.selected = this.dataFields.filter(value => value.default);
          //this.dataFieldsFilter = [...this.dataFields];
          this.dataFieldsFilter = this.dataFields.filter(obj => obj.value !== 'actions')
          if(this.userAdmin === 'PTA') {
            this.dataFieldsFilter = this.dataFields.filter(field =>!field.admin);
            this.selected = this.dataFields.filter(value => (value.default && !value.admin))
          }
          
        },

        close () {
            this.dialog = false;
            this.setStudentDataFields(this.selected);
            this.setStudentDataHeaders([])
            if(this.userAdmin === 'Admin'){
              let actionsIndex = this.selected.findIndex(obj => obj.value === 'actions');
              if(actionsIndex !== -1){
                  this.selected.push(this.selected.splice(actionsIndex, 1)[0]);
              }
            }
            this.setStudentDataHeaders(this.selected)
            this.$emit('update');
        }
    }
  }
</script>