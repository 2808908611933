<template>
     <v-row>
        <v-col class="pb-0" cols="8">
            <v-card width="720" flat>
                <v-card-subtitle class="font-weight-black primary--text">{{ formattedDate }}</v-card-subtitle>
                
                <v-card-text>                    
                    <ChartBar
                        v-if="dataLoaded"
                        :chart-data="chartData"
                    ></ChartBar>
                </v-card-text>

                <v-card-actions>
                    <v-btn text color="primary">Total Attendance: {{ totalAttendance }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary">Late Attendance: {{ lateAttendance }}</v-btn>
                </v-card-actions>

                <v-overlay
                    absolute
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9" 
                >
                    <v-progress-circular
                        indeterminate 
                        size="64"                        
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-col>
        <v-col
            cols="4"
        >
            <v-date-picker
                label="Select Date"
                v-model="dateSelected"
                elevation="6"
                @change="getChartData"
                full-width
            ></v-date-picker>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
// import DatePickerRange from './DatePickerRange'
import ChartBar from './ChartBar.vue'
// import ChartDoughnut from './ChartDoughnut.vue';
import { format, parseISO } from 'date-fns';
export default {
    props: {
        formClasses: {
            type: Array,
            default: () => []
        }
    },


    created () {
        this.initialize();
        
    },

    computed: {
        formattedDate () {
            return format(parseISO(this.dateSelected), 'EEEE, d LLLL yyyy')
        }
    },

    components: {
        // DatePickerRange,
        ChartBar,
        // ChartDoughnut, 
    },

    data: () => ({
        dateRange: null,
        selectedClassId: null,
        attendanceData: {
            datasets: [{
                label: 'Attendance',
                backgroundColor: '#f87979',
                data: [40, 20, 12]
            }]
        },
        chartData: {
            labels: ['Form 1', 'Form 2', 'Form 3', 'Form 4', 'Form 5', 'Form 6'],
            datasets: []
        },
        chartOptions: {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                
            },
            animation: {
                animateScale: true
            },  
            cutout: '80%',
            text: '39%',
        },
        dataLoaded: false,
        overlay: false,
        totalAttendance: '--',
        lateAttendance: '--',
        dateSelected: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }),

    methods: {
        ...mapMutations({
            setAttendanceSummaryDates: 'admin/setAttendanceSummaryDates',
            setAttendanceSummaryDate: 'admin/setAttendanceSummaryDate',
            
        }),

        ...mapActions({
            getAttendanceSummaryData: 'admin/getAttendanceSummaryData',
        }),

        initialize () {
            this.getChartData();
        },

        async getChartData () {
            this.setAttendanceSummaryDate(this.dateSelected);
            this.overlay = true;
            try {
                const { data } = await this.getAttendanceSummaryData();
                console.log(data);
                if(!Array.isArray(data)) 
                this.mapChartData(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
        },

        mapChartData (data) {
            let dataSets = [];
            let dataPresent = [];
            let dataAbsent = [];
            this.chartData.datasets = [];
            const [startDate] = Object.values(data);
            const { attendance_records } = startDate;
            const { attendance_school: { attendance: attendanceSchool } } = startDate;
            const { attendance_late: { attendance: attendanceLate }} = startDate;

            this.totalAttendance = attendanceSchool;
            this.lateAttendance = attendanceLate;
            Object.keys(attendance_records).forEach(formLevel => {
                dataPresent.push(attendance_records[formLevel].attendance);
                dataAbsent.push(attendance_records[formLevel].total - attendance_records[formLevel].attendance)
            })

            // Object.keys(data).forEach( date => {
            //     console.log(data);
            //     const { attendance_records } = data[date];
            //     const { attendance_school : { attendance } } = data[date];
            //     const { attendance_late: { attendance: attendanceLate } } = data[date];
            //     return;
            // })

            dataSets.push(
                {
                    label: 'Present',
                    data: dataPresent,
                    // backgroundColor:'rgba(54, 162, 235, 0.2)',
                    backgroundColor:'rgba(48, 88, 172, 0.4)',
                }
            )
            dataSets.push(
                {
                    label: 'Absent',
                    data: dataAbsent,
                    backgroundColor: 'rgba(190, 190, 190, 0.2)',                           
                }
            )
            this.chartData.datasets = dataSets;
            this.dataLoaded = true;
        },

    }
}
</script>